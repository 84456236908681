import React from 'react';
// import { homeText } from '../../assets/Text/HomeText';

const Hero = ({
  title1 = '',
  title2 = '',
  title3 = '',
  textTrans = '',
  align = 'center',
  imageSrc,
  appearance1,
  appearance2,
  appearance3,
  appearance4,
  h2Width = '',
  h1mLeft = '',
  h2mRight = '',
  heroTitle = '',
  wholeWidth = '',
}) => {
  return (
    <section
      className="w-full h-[200px]  min-[1440px]:h-[500px] backdrop-hero relative bg-cover bg-center  bg-no-repeat flex flex-col items-center justify-center bg-[#121111]"
      style={{ backgroundImage: imageSrc }}
    >
      {/* <img
        src="/pictures/Home/desktop-hero-clean.png"
        alt="Hero Img"
        className="bg-contain bg-center bg-no-repeat"
      /> */}
      <div className="backdrop2-hero absolute top-0 left-0 bottom-[0px] right-0 z-1"></div>
      <div className="backdrop3-hero absolute top-0 left-0 bottom-[-41px] min-[1440px]:bottom-[-81px] right-0 z-1"></div>
      <div
        className="w-[320px] min-[744px]:w-[450px] min-[744px]:text-[26px] min-[1440px]:w-[1162px] text-[20px] min-[1440px]:text-[40px] font-[400] text-[#F9F9F9]"
        style={{ width: wholeWidth }}
      >
        <h1
          className="relative uppercase italic mr-auto ml-[15px]"
          style={{
            textTransform: textTrans,
            display: appearance1,
            marginLeft: h1mLeft,
          }}
        >
          {title1}
        </h1>
        <h2
          className="relative  italic ml-auto w-[188px] min-[744px]:w-[250px] min-[1440px]:w-[100%] min-[1440px]:text-right mr-[15px] leading-6 min-[1440px]:leading-10"
          style={{
            display: appearance2,
            width: h2Width,
            marginRight: h2mRight,
          }}
        >
          {title2}
        </h2>
      </div>

      <div className="mx-auto w-[280px] min-[744px]:w-[365px]  min-[744px]:text-[26px] text-[20px] min-[1440px]:w-[650px] min-[1440px]:text-[40px] font-[400] text-[#F9F9F9]">
        <h1
          className="relative italic leading-6 min-[744px]:leading-8  min-[1440px]:leading-10 "
          style={{ display: appearance3, textAlign: align }}
        >
          {title3}
        </h1>
      </div>

      <div className="mx-auto hidden" style={{ display: appearance4 }}>
        {heroTitle}
      </div>

      <div className="hidden min-[1440px]:block z-[99] absolute bottom-[65px]">
        <img width="30" src="/pictures/HeroImages/herald.png" alt="Image" />
      </div>
    </section>
  );
};

export default Hero;
