import React from 'react';
import ContactForm from './ContactForm';
import Subtitle from '../Subtitle';

const ContactUsDesktop = () => {
  return (
    <section
      id="contact_us"
      className="px-[15px] hidden min-[1440px]:block max-w-[1170px] mx-auto"
    >
      <Subtitle title="Замовити консультацію спеціаліста" />
      <div
        className="min-h-[200px] min-[1440px]:mx-auto min-[1440px]:flex w-[970px] h-[492px] rounded-[200px] min-[1440px]:mt-[25px] relative bg-center bg-cover items-center justify-center"
        style={{
          backgroundImage: 'url("/pictures/contact-us.jpg")',
        }}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 footer-backdrop rounded-[200px] z-[10]"></div>
        <ContactForm />
      </div>
    </section>
  );
};

export default ContactUsDesktop;
