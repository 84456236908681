import React from 'react';
import { RTALawText } from '../../assets/Text/RTALawText';

const RTAHero = () => {
  return (
    <div className="w-[295px] min-[744px]:w-[375px] min-[744px]:text-[26px] min-[1440px]:w-[550px] text-[20px] min-[1440px]:text-[40px] font-[400] text-[#F9F9F9]">
      <h1 className="relative italic mr-auto ml-[15px]">
        {RTALawText.heroTitle1}
      </h1>
      <h2 className="relative  italic ml-auto w-[188px] min-[744px]:w-[250px] min-[1440px]:w-[100%] text-right mr-[15px] leading-6 min-[1440px]:leading-10">
        {RTALawText.heroTitle2}
      </h2>
    </div>
  );
};

export default RTAHero;
