import React from 'react';

const Logo = () => {
  return (
    <a href="/">
      <img
        src="/pictures/Logo/WholeLogo.png"
        className="z-12 h-auto"
        alt="Corporate Logo"
      />
    </a>
  );
};

export default Logo;
