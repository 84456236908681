import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { BiSolidUpArrow } from "react-icons/bi";
import { IoIosArrowUp } from "react-icons/io";

const ToTheTopBtn = () => {
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showBtn && (
        <div className="z-[99] flex w-full justify-end items-center fixed bottom-0 bg-[transparent]">
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="z-[99] absolute w-10 h-10 right-[3%] bottom-[20px] hover:cursor-pointer hover:bg-[#333] bg-[#004da5ae] transition ease-in-out delay-150 rounded-full focus:bg-[#333] hover:scale-[1.1] min-[744px]:w-14 min-[744px]:h-14 min-[744px]:right-[48.4%] min-[744px]:bottom-5 "
            onClick={scrollUp}
          >
            <motion.span
              initial={{ transform: "rotate(180deg)" }}
              animate={{ transform: "rotate(0deg)" }}
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center bg-[transparent]
              "
            >
              {/* <BiSolidUpArrow /> */}
              <IoIosArrowUp className="w-7 h-7" />
            </motion.span>
            {/* <motion.span
              initial={{ transform: 'rotate(180deg)' }}
              animate={{ transform: 'rotate(0deg)' }}
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center mt-[-9px]
              ">
              <BiSolidUpArrow />
            </motion.span> */}
          </motion.button>
        </div>
      )}
    </>
  );
};

export default ToTheTopBtn;
