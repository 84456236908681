import React from 'react';
import Subtitle from '../Subtitle';
import Paragraph from './Paragraph';

const PagesTextBlock = ({ title, text, mTop, mBottom }) => {
  return (
    <section className="max-w-[1170px] min-[1440px]:px-0 mx-auto relative z-[2]">
      <Subtitle title={title} mTop={mTop} mBottom={mBottom} />

      <Paragraph text={text} />
    </section>
  );
};

export default PagesTextBlock;
