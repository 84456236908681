export const RTALawText = {
  heroTitle1: 'Допомога адвоката',
  heroTitle2: 'при ДТП',
  title: 'Допомога адвоката при ДТП',
  text1:
    'Наша юридична компанія надає професійні послуги у галузі дорожньо-транспортних пригод. Ми готові допомогти вам захистити ваші права та інтереси у разі аварії чи інших ситуацій на дорозі. Наші послуги включають: \n\n1. Консультації щодо дорожніх пригод. \n2. Збір та аналіз доказів. \n3. Представництво інтересів. \n4. Стягнення компенсації. \n5. Допомога зі страховими питаннями. \n\nНаша команда досвідчених юристів зосереджена на досягненні найкращих результатів для наших клієнтів у дорожньо-транспортних пригодах, забезпечуючи професіоналізм та ефективність у кожному випадку.',
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Усна, письмова, виїзна консультація адвоката по питанням дорожньо-транспортних пригод.',
    rowPrice: '2000 ₴',
  },

  {
    rowTitle:
      'Ознайомлення з матеріалами справи на будь-якій стадії кримінального чи адміністративного процесу.',
    rowPrice: '6000 ₴',
  },

  {
    rowTitle:
      'Юридичний аудит документів - складених протоколів, постанов по питанням дорожньо-транспортних пригод.',
    rowPrice: '6000 ₴',
  },

  {
    rowTitle:
      'Розробка стратегії юридичного супроводу по питанням дорожньо-транспортних пригод.',
    rowPrice: '6000 ₴',
  },

  {
    rowTitle:
      'Оскарження дій чи бездіяльності поліцейських, складених протоколів, постанов по питанням дорожньо-транспортних пригод.',
    rowPrice: '6000 ₴',
  },
  {
    rowTitle:
      'Повернення посвідчень на право користування транспортним засобом чи продовження дії тимчасових посвідчень на право користування транспортним засобом.',
    rowPrice: '10000 ₴',
  },
  {
    rowTitle:
      'Участь адвоката в кримінальному провадженні та адміністративному процесі.',
    rowPrice: '30000 ₴',
  },
  {
    rowTitle: 'Участь адвоката у судових засіданнях.',
    rowPrice: '4000 ₴',
  },
  {
    rowTitle:
      'Стягнення страхового відшкодування в результаті дорожньо-транспортної пригоди.',
    rowPrice: '12000 ₴',
  },
];
