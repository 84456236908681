import React from 'react';

import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

import { RTALawText, tableInfo } from '../assets/Text/RTALawText';
import HeaderSecondary from '../components/Header/HeaderSecondary';
import RTAHero from '../components/Hero/RTAHero';

const RoadTrafficAccident = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title3={RTALawText.title}
        // title2={RTALawText.heroTitle2}
        textTrans="initial"
        imageSrc={"url('/pictures/HeroImages/desktop-rta3.jpg')"}
        h1mLeft="30px"
        h2Width={'110px'}
        heroTitle={<RTAHero />}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
        appearance4={'none'}
        // wholeWidth={'320px'}
      />

      <PagesTextBlock
        title={RTALawText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={RTALawText.text1}
      />

      <Table
        tableTitle={RTALawText.tableTitle}
        tablePrice={RTALawText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default RoadTrafficAccident;
