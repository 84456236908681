export const RealEstateLawText = {
  heroTitle: 'Послуги з нерухомістю',
  title: 'Послуги з нерухомістю',
  text: "Ми пропонуємо широкий спектр послуг, включаючи консультації з купівлі, продажу та оренди нерухомості, перевірку юридичної чистоти об'єктів, складання та аналіз договорів, а також вирішення спорів та захист ваших прав у суді. Наша команда досвідчених юристів забезпечить вас професійними та індивідуальними рішеннями, а також надійною підтримкою на кожному етапі угоди. Ми прагнемо до високої якості послуг, ефективності та задоволення потреб кожного клієнта. Довірте нам свої нерухомі питання, і ми забезпечимо вас миром та впевненістю у ваших угодах.",
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Правовий аудит об’єкту нерухомості, що купується (перевірка правовстановлюючих документів на земельну ділянку та інше нерухоме майно, аналіз можливих існуючих обтяжень та наявності відповідних дозволів у забудовника).',
    rowPrice: '1500 ₴',
  },

  {
    rowTitle:
      'Допомога адвоката при реєстрації права на нерухомість, узаконенні самовільного будівництва, супроводі угод з нерухомістю, переведенні житла у нежитловий фонд.',
    rowPrice: '2000 ₴',
  },

  {
    rowTitle:
      'Представництво інтересів клієнта в Центрі надання адміністративних послуг, органах містобудування та архітектури, органах земельних ресурсів та інших органах і організаціях.',
    rowPrice: '2000 ₴',
  },

  {
    rowTitle:
      'Допомога адвоката при реєстрації права власності на землю, на використання землі, зміні цільового призначення, приватизації, присвоєнні кадастрового номера земельної ділянці, експертній грошовій оцінці земельної ділянки, отриманні довідки про відсутність будівель на земельній ділянці.',
    rowPrice: '4000 ₴',
  },
];
