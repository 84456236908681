import React from "react";
import { validationSchema } from "./validationSchema";
import { useFormik } from "formik";
import { motion } from "framer-motion";
// import { Formik,Form, Field  } from 'formik';

import { contactText } from "../../assets/Text/ContactUs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      phone: "",
      name: "",
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = new FormData();
      Object.entries(values).forEach(([key, val]) => {
        body.append(key, val);
      });

      const res = await fetch(
        "https://getform.io/f/60087e4b-6a8c-4d7d-bdf1-8f827543b9f9", //omlegal.services2023@gmail.com
        // "https://getform.io/f/b6eb4c30-4aae-4130-a5e2-cfba62b93401", //tim test
        {
          method: "POST",
          headers: { accept: "application/json" },
          body,
        }
      );

      if (!res.ok) {
        console.error(res.status, await res.text());
      } else {
        console.log(await res.json());
        await new Promise((resolve) => setTimeout(resolve, 700));
        toast.success("Дякуємо! Вам скоро зателефонують :)");
        // toast.success(contactText.toast);
        console.log("submitted");
        actions.resetForm();
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className={
        "w-full h-full flex flex-col items-center justify-center space-y-[20px] relative z-[11] px-[55px] min-[400px]:px-[75px] min-[600px]:px-[100px]"
      }
    >
      <div className="relative w-full">
        <input
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          type="tel"
          name="phone"
          id="phone"
          placeholder={contactText.phone}
          className={
            errors.phone && touched.phone
              ? "relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px] border-red-700;"
              : "relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] border-[#F9F9F9] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px];"
          }
        />

        {errors.phone && touched.phone ? (
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute top-[7px] right-[10px] text-red-600 min-[744px]:text-[20px] min-[1130px]:top-[10px] min-[1130px]:right-[25px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] min-[1440px]:top-[12px] text-base"
          >
            {errors.phone}
          </motion.div>
        ) : null}
      </div>

      <div className="relative w-full">
        <input
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          name="name"
          id="name"
          placeholder={contactText.name}
          className={
            errors.name && touched.phone
              ? "relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px] border-red-700;"
              : "relative w-full min-w-[180px] min-h-[30px] rounded-[25px] bg-transparent border-[2px] border-[#F9F9F9] text-[#F9F9F9] text-base pl-[15px] py-[5px] min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1130px]:pl-[70px] min-[1440px]:text-[24px];"
          }
        />

        {errors.name && touched.name ? (
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute top-[7px] right-[10px] text-red-600 text-base min-[744px]:text-[20px] min-[1130px]:top-[10px] min-[1130px]:right-[25px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] min-[1440px]:top-[12px];"
          >
            {errors.name}
          </motion.div>
        ) : null}
      </div>
      <button
        type="submit"
        // value={contactText.button}
        disabled={isSubmitting}
        className="rounded-[25px] py-[3px] px-[20px] bg-transparent border-[2px] border-[#F9F9F9] text-[#F9F9F9] text-base min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1130px]:py-[10px] min-[1440px]:text-[24px];"
      >
        {contactText.button}
      </button>
      <ToastContainer />
    </form>
  );
};

export default ContactForm;
