export const CivilFamilyText = {
  heroTitle1: "Цивільне та сімейне право",
  heroTitle2: "",
  title: "Цивільне та сімейне право",
  text: "Наша юридична компанія надає широкий спектр послуг із цивільного та сімейного права. Ми пропонуємо консультації з питань цивільного права, захист інтересів у суді у разі суперечок, пов'язаних із договорами, боргами та іншими цивільними питаннями. У сімейному праві ми допомагаємо з розлученнями, поділом майна, усиновленням та опікою, а також регулюванням відносин між подружжям. Ми також пропонуємо допомогу у позасудовому вирішенні суперечок, використовуючи методи медіації та альтернативні способи вирішення конфліктів. Наша команда досвідчених юристів готова допомогти вам у вирішенні ваших правових питань.",
  tableTitle: "Вид адвокатських (юридичних послуг) послуг",
  tablePrice: "Ціна",
};

export const tableInfo = [
  {
    rowTitle: "Припинення, розірвання шлюбу, визнання шлюбу недійсним.",
    rowPrice: "7000 ₴",
  },

  {
    rowTitle:
      "Визнання недійсним шлюбного договору, застосування наслідків недійсності шлюбного договору.",
    rowPrice: "7000 ₴",
  },

  {
    rowTitle: "Стягнення аліментів, збільшення чи зменшення розміру аліментів.",
    rowPrice: "8000 ₴",
  },

  {
    rowTitle: "Визначення місця проживання дитини, участь у вихованні дитини.",
    rowPrice: "12000 ₴",
  },

  {
    rowTitle: "Встановлення батьківства, материнства.",
    rowPrice: "20000 ₴",
  },
  {
    rowTitle:
      "Скасування усиновлення (удочеріння ) чи визнання його недійсним.",
    rowPrice: "30000 ₴",
  },
  {
    rowTitle:
      "Поділ майна (рухомого та нерухомого) набутого за час перебування у шлюбі подружжя.",
    rowPrice: "50000 ₴",
  },
  {
    rowTitle:
      "Складання проекту шлюбного договору, договорів якими врегульовуються сімейні відносини з будь яких питань, що виникають під час перебування у шлюбі, спільного проживання сім’єю.",
    rowPrice: "7000 ₴",
  },
  {
    rowTitle:
      "Складання проекту шлюбного договору, договорів якими врегульовуються сімейні відносини з будь яких питань, що виникають під час перебування у шлюбі, спільного проживання сім’єю.",
    rowPrice: "22000 ₴",
  },
  {
    rowTitle:
      "Складання проектів договорів про порядок користування житловім будинком, квартирою, іншою будівлею чи спорудою, земельною ділянкою.",
    rowPrice: "7000 ₴",
  },
  {
    rowTitle:
      "Вжиття правових заходів щодо виділу в натурі частки одного із подружжя у праві спільної сумісної власності або визначення порядку користування майном",
    rowPrice: "12000 ₴",
  },
];
