import React from "react";
// import Hero from '../components/Hero/Hero';
import HomeInfoSection from "../components/HomeInfoSection/HomeInfoSection";
import Services from "../components/Services/Services";
import Subtitle from "../components/Subtitle";
import "../App.css";
// import { Helmet } from 'react-helmet';

import { homeText } from "../assets/Text/HomeText";
import { contactText } from "../assets/Text/ContactUs";
import Header from "../components/Header/Header";
import HeroVideo from "../components/Hero/HeroVideo";

const Homepage = () => {
  return (
    <div className="mx-auto">
      {/* <Helmet>
        <meta name="google-site-verification" content="CBZ3R8WpndsaseeoVFsHpd8B-mFg_UyucGHpwL8M_Rk" />

        <title>
          OM Legal. Юридичні послуги, консультації, правова допомога
        </title>

        <meta name="keywords" content="юридичні послуги, юристи, правова допомога, юридичні консультації, юридичне обслуговування" />

        <meta name="description" content="Команда досвідчених юристів надає широкий спектр юридичних послуг та консультацій. Захистіть свої права з нами!" />
      </Helmet> */}
      <header className="App-header">
        <Header />
      </header>
      {/* <Hero
        title1={homeText.heroTitle1}
        title2={homeText.heroTitle2}
        textTrans="uppercase"
        imageSrc={"url('/pictures/Home/desktop-hero-clean3.jpg')"}
        appearance1={'block'}
        appearance2={'block'}
        h1mLeft={'15px'}
        appearance3={'none'}
      /> */}
      <HeroVideo
        title1={""}
        title2={""}
        textTrans="uppercase"
        // imageSrc={"url('/pictures/Home/desktop-hero-clean3.jpg')"}
        appearance1={"block"}
        appearance2={"block"}
        h1mLeft={"15px"}
        appearance3={"none"}
      />
      <HomeInfoSection
        title={homeText.title2}
        text1={homeText.text1}
        text2={homeText.text2}
        id={"about_us"}
        mBottom={"15px"}
        image={"url('/pictures/Home/desktop-about-us-clean3.jpg')"}
      />
      <Services />
      <HomeInfoSection
        className="mt-[40px]"
        title={homeText.title4}
        text1={homeText.text3}
        id={"why_us"}
        mTop={"25px"}
        mBottom={"15px"}
        image={"url('/pictures/Home/desktop-why-us-clean3.jpg')"}
        // display={'none'}
      />
      <div className="min-[1440px]:hidden  max-w-[1170px] mx-auto">
        <Subtitle title={contactText.title} mTop={"25px"} mBottom={"5px"} />
      </div>{" "}
    </div>
  );
};

export default Homepage;
