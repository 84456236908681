export const USRText = {
  heroTitle1: 'Реєстрація, перереєстрація',
  heroTitle2: 'внесення змін до ЄДРПОУ',
  heroTitle3: 'Реєстрація, перереєстрація внесення змін до ЄДРПОУ',
  title: 'Реєстрація, перереєстрація внесення змін до ЄДРПОУ',
  text: "Ми надаємо повний комплекс послуг, пов'язаних із реєстрацією, перереєстрацією та ліквідацією підприємств, а також внесенням змін до ЄДРПОУ (єдиний державний реєстр підприємств та організацій України). Наша команда досвідчених юристів та фахівців у галузі корпоративного права готова надати професійну підтримку у даних сферах. Ми гарантуємо високу якість обслуговування, конфіденційність та професійний супровід на всіх етапах ваших корпоративних процедур. Наша мета – забезпечити безпеку та правову надійність вашого підприємства відповідно до вимог законодавства.",
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle: 'Реєстрація ФОП (фізична особа-підприємець).',
    rowPrice: '4000 ₴',
  },

  {
    rowTitle: 'Реєстрація ТОВ, ПП. Благодійних фондів, Громадських організацій',
    rowPrice: '5000 ₴',
  },

  {
    rowTitle:
      'Перереєстрація ТОВ, ПП, Благодійних фондів, Громадських організацій.',
    rowPrice: '5000 ₴',
  },

  {
    rowTitle: 'Ліквідація та банкрутство ФОП (Фізична особа-підприємець).',
    rowPrice: '10000 ₴',
  },

  {
    rowTitle:
      'Ліквідація та банкрутство ТОВ, ПП, Благодійних фондів, Громадських організацій',
    rowPrice: '20000 ₴',
  },
  {
    rowTitle: 'Отримання витягу, виписки, довідки, та інших документів.',
    rowPrice: '1000 ₴',
  },
];
