import TelegramIcon from '../svg/telegramIcon';
// import PhoneIcon from '../svg/PhoneIcon';
import ViberIcon from '../svg/ViberIcon';
import WhatsAppIcon from '../svg/WhatsAppIcon';
const telegramLink = 'https://t.me/omlegal';
// const telegramLink2 = 'https://t.me/Omlegalservices';
const viberLink = 'viber://chat?number=380638326057';
const whatsappLink = 'https://wa.me/380638326057';

export const menuListInfo = [
  // { value: 'Головна', link: '/' },
  { value: 'Про нас', link: '/#about_us' },
  { value: 'Послуги', link: '#services' },
  { value: 'Чому ми?', link: '#why_us' },
  { value: 'Зв’язок', link: '#contact_us' },
  { value: '+38063 832 6057', link: 'tel:+380638326057' },
  { value: <TelegramIcon />, link: telegramLink },
  { value: <ViberIcon />, link: viberLink },
  { value: <WhatsAppIcon />, link: whatsappLink },
  // { value: <PhoneIcon />, link: "tel:+380638326057" },
];

export const menuListInfoEN = [
  { value: 'Home', link: '/' },
  { value: 'About us', link: '#about_us' },
  { value: 'Services', link: '#services' },
  { value: 'Why us?', link: '#why_us' },
  { value: 'Contact', link: '#contact_us' },
];

export const menuListInfoSecondary = [
  // { value: 'Головна', link: '/' },
  // { value: 'Про нас', link: '/#about_us' },
  // { value: 'Чому ми?', link: '#why_us' },
  { value: 'Послуги', link: '#table' },
  { value: 'Зв’язок', link: '#contact_us' },
  { value: '+38063 832 6057', link: 'tel:+380638326057' },
  { value: <TelegramIcon />, link: telegramLink },
  { value: <ViberIcon />, link: viberLink },
  { value: <WhatsAppIcon />, link: whatsappLink },
  // { value: <PhoneIcon />, link: "tel:+380638326057" },
];
