export const BankingLawText = {
  heroTitle: 'Банківське право',
  title: 'Банківське право',
  text: "Наша юридична компанія надає широкий спектр послуг у галузі банківського права. Ми спеціалізуємося на наданні професійної юридичної допомоги у питаннях, пов'язаних з банківськими операціями та відносинами між клієнтами та банками. Ми пропонуємо консультації з банківських послуг, складання та аналіз юридичної документації, включаючи договори, кредитні угоди та гарантії. Ми також надаємо супровід у разі суперечок із банками, включаючи стягнення заборгованостей, захист прав споживачів та представництво у суді. Наша команда досвідчених юристів готова допомогти вам у вирішенні складних юридичних питань у сфері банківського права, забезпечуючи професіоналізм та ефективність.",
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Захист інтересів вкладників в поверненні грошових коштів у випадку банкрутства банку включаючи роботу з Фондом гарантування вкладів фізичних осіб.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle:
      'Реструктуризації кредитної заборгованості – списання частини боргу, погашення кредиту єдиним платежем із зменшенням суми заборгованості, розміру щомісячних платежів та розміру відсотків, перенесення строків платежів по кредиту.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle:
      'Визнання кредитних договорів недійсними на підставі незаконного підвищення відсотків, нав’язування банком отримання кредиту у валюті, прихованих платежів і т.і. та повернення позичальнику сплачених ним на користь банку коштів.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle:
      'Визнання іпотечного договору або договору застави недійсними і виведення майна з-під заборони відчуження.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle:
      'Захист клієнта при стягненні банком заборгованості за договором фінансового лізингу та «кредитній картці».',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Стягнення із банку на користь вкладників або їх спадкоємців, коштів за договором банківського вкладу, а саме суми вкладу, процентів за користування вкладом, 3% річних та суми інфляційних втрат, які нараховуються до моменту фактичного повернення банком вкладу.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Юридичний аудит кредитних договорів, договорів поруки, іпотеки, застави та договорів банківського вкладу.',
    rowPrice: '8000 ₴',
  },
  {
    rowTitle: 'Захист від дій колекторських організацій.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Захист боржника у виконавчому провадженні – скасування постанов про відкриття виконавчого провадження, постанов про арешт майна, публічних торгів.',
    rowPrice: 'По домовленості',
  },
];
