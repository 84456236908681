// import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// import Header from './components/Header/Header';
// import Hero from './components/Hero/Hero';
// import HomeInfoSection from './components/HomeInfoSection/HomeInfoSection';
// import Services from './components/Services/Services';
// import Subtitle from './components/Subtitle';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';

import { servicesLinks } from './assets/servicesInfo';
import ContactUsDesktop from './components/ContactUs/ContactUsDesktop';
import ToTheTopBtn from './components/ToTheTopBtn';
// import { homeText } from './assets/Text/HomeText';
// import { contactText } from './assets/Text/ContactUs';

// import Homepage from './pages/Homepage';
// import AdministrativeLaw from './pages/AdministrativeLaw';

function App() {
  const [isScreenMoreThan1440, setIsScreenMoreThan1440] = useState(
    window.innerWidth > 1440
  );
  useEffect(() => {
    const handleResize = () => {
      setIsScreenMoreThan1440(window.innerWidth > 1440);
    };

    //adding eventListener of the window's resize
    window.addEventListener('resize', handleResize);

    //removing eventListener when dismounting the component
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App mx-auto">
      {/* <header className="App-header">
        <Header />
      </header> */}
      <main>
        <Routes>
          {servicesLinks?.map((item, idx) => (
            <Route path={item.link} element={item.component} />
          ))}
        </Routes>
      </main>

      {isScreenMoreThan1440 ? <ContactUsDesktop /> : <ContactUs />}

      <ToTheTopBtn />

      <Footer />
    </div>
  );
}

export default App;
