export const CriminalLawText = {
  heroTitle: 'Кримінальне право',
  title: 'Кримінальне право',
  text: 'Ми спеціалізуємося на послугах із кримінального права, забезпечуючи експертний юридичний супровід у кримінальних справах. Ми пропонуємо широкий спектр послуг, включаючи консультації та захист прав обвинувачених у кримінальних злочинах. Наша команда складається з досвідчених кримінальних адвокатів, які готові подати ваші інтереси в суді, розробити стратегію захисту, аргументовано подати докази та забезпечити найкращий результат для вас. Ми забезпечуємо конфіденційність, професіоналізм та відданість захисту прав наших клієнтів у кримінальних справах.',
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Ініціювання відкриття кримінального провадження та внесення його в Єдиний реєстр досудових розслідувань',
    rowPrice: '3500 ₴',
  },

  {
    rowTitle:
      'Представництво інтересів у кримінальному провадженні (захист підозрюваного, обвинуваченого, свідка, потерпілого, цивільного позивача або відповідача) під час досудового розслідування',
    rowPrice: '50000 ₴',
  },

  {
    rowTitle:
      'Представництво інтересів у кримінальному провадженні (участь при проведенні допитів, очних ставок, обшуків, виїмок, та інших слідчих/процесуальних діях) під час досудового розслідування',
    rowPrice: '10000 ₴',
  },

  {
    rowTitle:
      'Представництво інтересів у кримінальному провадженні ( відвідування обвинуваченого/підозрюваного у слідчому ізоляторі) під час досудового розслідування',
    rowPrice: '15000 ₴',
  },

  {
    rowTitle:
      'Оскарження дій і рішень слідчого, прокурора, судді на досудовому розслідуванні',
    rowPrice: '10000 ₴',
  },
  {
    rowTitle:
      'Підготовка адвокатом процесуальних документів (запитів, клопотань, заяв, скарг, заперечень та інших документів) під час досудового розслідування',
    rowPrice: '8000 ₴',
  },
  {
    rowTitle:
      'Ініціювання та організація проведення необхідних та незалежних експертиз.',
    rowPrice: '8000 ₴',
  },
  {
    rowTitle:
      'Представництво інтересів у кримінальному провадженні (участь адвоката при розгляді кримінальної справи в судах першої інстанції, апеляційному, касаційному, Верховному суді України)',
    rowPrice: '70000 ₴',
  },
  {
    rowTitle:
      'Заперечення проти дій судді, відвід складу суду і інших учасників процесу.',
    rowPrice: '2000 ₴',
  },
  {
    rowTitle: 'Оскарження вироків суду (апеляційна, касаційна скарга)',
    rowPrice: '25000 ₴',
  },
  {
    rowTitle:
      'Вимога адвоката про відшкодування моральної та майнової шкоди при незаконному кримінальному переслідуванню клієнта, за яким визнано право на реабілітацію.',
    rowPrice: '15000 ₴',
  },
  {
    rowTitle:
      'Спори про встановлення наявності чи відсутності компетенції (повноважень) суб’єкта владних повноважень.',
    rowPrice: '20000 ₴',
  },
  {
    rowTitle:
      'Оскарження правових актів індивідуальної дії, а також дій чи бездіяльності суб’єктів владних повноважень, які стосуються інтересів конкретної особи.',
    rowPrice: '15000 ₴',
  },
];
