export const CommercialLawText = {
  heroTitle: 'Господарське право',
  title: 'Господарське право',
  text: 'Ми надаємо послуги господарського права. Пропонуємо комплексний юридичний супровід у сфері підприємництва та комерційної діяльності. Наші послуги включають консультації з організації та ведення бізнесу, складання та аналіз комерційних договорів, захист інтересів клієнтів у суді та арбітражі, вирішення спорів з контрагентами та державними органами. Ми також надаємо допомогу в питаннях корпоративного права, реєстрації та ліквідації компаній, злиття та поглинання. Наша команда досвідчених юристів готова надати професійне вирішення ваших господарських питань, забезпечуючи надійність та ефективність у бізнес-процесах.',
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Розробка договорів: поставки, оренди, позики, про надання послуг та інших.',
    rowPrice: '2000 ₴',
  },

  {
    rowTitle:
      'Складання попередніх договорів, угод та будь-яких правочинів на будь-який предмет з будь-якими умовами, що не суперечать законодавству України.',
    rowPrice: 'від 30000 ₴',
  },

  {
    rowTitle:
      'Представництво інтересів клієнта при взаємодії з органами державної влади.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle:
      'Представництво інтересів клієнта на переговорах з постачальниками, покупцями та іншими можливими контрагентами.',
    rowPrice: 'По домовленості',
  },

  {
    rowTitle: 'Представництво інтересів клієнта в корпоративних спорах.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle: 'Представництво інтересів клієнта в банківських спорах.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle: 'Послуги юриста у правовому аналізі будь-яких видів договорів.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Складання листів, довідок, заяв на будь-який предмет здійснення господарської діяльності.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Розробка внутрішніх документів, у тому числі, протоколів і статутів.',
    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Абонентське обслуговування підприємств (усна, письмова консультація, договірна робота, трудові відносини).',

    rowPrice: 'По домовленості',
  },
  {
    rowTitle:
      'Консультація та складання відповідних документів з кадрових питань.',
    rowPrice: 'По домовленості',
  },
];
