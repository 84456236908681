import React from 'react';
import { menuListInfoSecondary } from '../../assets/Header/menuListInfo';
import { Link } from 'react-router-dom';

const MenuListSecondary = ({ language }) => {
  return (
    <>
      <ul className="flex space-x-6 header-menu-list mr-[20px] text-white items-center">
        {/* {language ? ( */}
        {/* <> */}
        <li className="min-[744px]:flex space-x-6">
          <Link to={'/'}>Головна</Link>
          {/* <Link to={'/#about_us'}>Про нас</Link> */}
        </li>
        {menuListInfoSecondary?.map((item, idx) => (
          <li className="min-[744px]:flex space-x-6">
            <a key={idx} href={item.link}>
              {item.value}
            </a>
          </li>
        ))}
        {/* </> */}
        {/* // ) : (
        <>
          {menuListInfoEN?.map((item, idx) => (
            <li key={idx}>
              <a href={item.link}>{item.value}</a>
            </li>
          ))}
        </>
        // )} */}
      </ul>
    </>
  );
};

export default MenuListSecondary;
