import { motion } from 'framer-motion';
import React from 'react';
import { menuListInfo } from '../../assets/Header/menuListInfo';
import { Link } from 'react-router-dom';

const MobileMenuList = ({ show, setShow, language, changeLang }) => {
  return (
    <>
      {/* {language ? (
        <> */}
      {show ? (
        <motion.ul
          initial={{ height: '520px', opacity: 0, top: '-150px' }}
          animate={{ height: '0px', opacity: 0, top: '-150px' }}
          transition={{ duration: 0.3 }}
          className="absolute top-[50px] left-[0px] header-menu-list mr-[20px] w-full h-[520px] text-white text-[16px] leading-5 font-bold bg-[#0A0909]/95 z-[16] flex flex-col items-center justify-center space-y-4 min-[744px]:hidden"
        >
          <li className="flex flex-col items-center justify-center space-y-4">
            <Link to={'/'} onClick={() => setShow(true)}>
              Головна
            </Link>
            {menuListInfo?.map((item, idx) => (
              <a key={idx} href={item.link} onClick={() => setShow(true)}>
                {item.value}
              </a>
            ))}
          </li>
        </motion.ul>
      ) : (
        <motion.ul
          initial={{ height: '50px', opacity: 0, top: '-150px' }}
          animate={{ height: '520px', opacity: 1, top: '50px' }}
          transition={{ duration: 0.3 }}
          className="absolute top-[50px] left-[0px] header-menu-list mr-[20px] w-full h-[520px] text-white text-[16px] leading-5 font-bold bg-[#0A0909]/95 z-[16] flex flex-col items-center justify-center space-y-4 min-[744px]:hidden"
        >
          <li className="flex flex-col items-center justify-center space-y-4">
            <Link to={'/'} onClick={() => setShow(true)}>
              Головна
            </Link>
            {menuListInfo?.map((item, idx) => (
              <a key={idx} href={item.link} onClick={() => setShow(true)}>
                {item.value}
              </a>
            ))}
          </li>
        </motion.ul>
      )}
      {/* </>
      // ) : (
      //   <>
      //     {show ? (
      //       <motion.ul
      //         initial={{ height: '520px', opacity: 0, top: '-150px' }}
      //         animate={{ height: '0px', opacity: 0, top: '-150px' }}
      //         transition={{ duration: 0.3 }}
      //         className="absolute top-[50px] left-[0px] header-menu-list mr-[20px] w-full h-[520px] text-white text-[16px] leading-5 font-bold bg-[#0A0909]/95 z-[16] flex flex-col items-center justify-center space-y-4 min-[744px]:hidden"
      //       >
      //         {menuListInfoEN?.map((item, idx) => (
      //           <li key={idx}>
      //             <a href={item.link} onClick={() => setShow(true)}>
      //               {item.value}
      //             </a>
      //           </li>
      //         ))}

      //       </motion.ul>
      //     ) : (
      //       <motion.ul
      //         initial={{ height: '50px', opacity: 0, top: '-150px' }}
      //         animate={{ height: '520px', opacity: 1, top: '50px' }}
      //         transition={{ duration: 0.3 }}
      //         className="absolute top-[50px] left-[0px] header-menu-list mr-[20px] w-full h-[520px] text-white text-[16px] leading-5 font-bold bg-[#0A0909]/95 z-[16] flex flex-col items-center justify-center space-y-4 min-[744px]:hidden"
      //       >
      //         {menuListInfoEN?.map((item, idx) => (
      //           <li key={idx}>
      //             <a href={item.link} onClick={() => setShow(true)}>
      //               {item.value}
      //             </a>
      //           </li>
      //         ))}

      //       </motion.ul>
      //     )}
      //   </>
      // )} */}
    </>
  );
};

export default MobileMenuList;
