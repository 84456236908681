import React from 'react';
import Subtitle from '../Subtitle';
import { homeText } from '../../assets/Text/HomeText';
import { servicesInfo, servicesInfo2, servicesInfoLastTwo } from '../../assets/servicesInfo';
import Service from './Service';

const Services = () => {
  const isLessThan1130px = window.matchMedia('(max-width: 1129px)').matches;

  const isMoreThan1130px = window.matchMedia('(min-width: 1130px)').matches;

  return (
    <section id="services" className="mt-[20px] max-w-[1170px] mx-auto">
      <Subtitle title={homeText.title3} />

      {isLessThan1130px && (
        <ul className="grid grid-cols-2 grid-rows-auto justify-center min-[744px]:px-[40px] w-full h-full gap-[15px] mt-[5px] px-[15px] min-[1130px]:grid-cols-3 min-[1130px]:grid-rows-auto min-[1280px]:px-0">
        {servicesInfo?.map((item, idx) => (
          <Service
            key={item.image}
            title={item.title}
            link={item.link}
            image={item.image}
          />
        ))}
      </ul>
      )}

      {isMoreThan1130px && (
        <ul className="grid grid-cols-2 grid-rows-auto justify-center min-[744px]:px-[40px] w-full h-full gap-[15px] mt-[5px] px-[15px] min-[1130px]:grid-cols-3 min-[1130px]:grid-rows-auto min-[1280px]:px-0">
        {servicesInfo2?.map((item, idx) => (
          <Service
            key={item.image}
            title={item.title}
            link={item.link}
            image={item.image}
          />
        ))}

        <div className='flex justify-between items-center col-span-3 gap-5'>
        {servicesInfoLastTwo?.map((item, idx) => (
          <Service
            key={item.image}
            title={item.title}
            link={item.link}
            image={item.image}
            gridStretch={item.gridStretch}
            itselfWidth={item.itselfWidth}
          />
        ))}
        </div>
      </ul>
      )}
      
    </section>
  );
};

export default Services;
