import React from 'react';

import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

import { CommercialLawText, tableInfo } from '../assets/Text/CommercialLawText';
import HeaderSecondary from '../components/Header/HeaderSecondary';

const CommercialLaw = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title3={CommercialLawText.heroTitle}
        textTrans="initial"
        align="center"
        imageSrc={"url('/pictures/HeroImages/desktop-commercial3.jpg')"}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
      />

      <PagesTextBlock
        title={CommercialLawText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={CommercialLawText.text}
      />

      <Table
        tableTitle={CommercialLawText.tableTitle}
        tablePrice={CommercialLawText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default CommercialLaw;
