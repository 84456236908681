import React from "react";

const ListItem = ({ text = "" }) => {
  return (
    <li className="whitespace-pre-line mb-4 w-[100%] px-[15px] relative about__us-text min-[744px]:text-[20px] min-[744px]:px-[40px] min-[1170px]:text-[22px] min-[1280px]:px-0 min-[1440px]:text-[24px]">
      {text}
    </li>
  );
};

export default ListItem;
