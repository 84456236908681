import React from "react";

const ListItemTitle = ({ text = "" }) => {
  return (
    <h2 className="mt-7 mb-5 text-[#F9F9F9] text-[20px] min-[744px]:text-[26px] min-[1170px]:text-[30px] min-[1440px]:text-[34px] italic font-[700] ml-[15px] min-[744px]:ml-[40px] min-[1280px]:ml-0 ">
      {text}
    </h2>
  );
};

export default ListItemTitle;
