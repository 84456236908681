import React from 'react';
import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

import { CivilFamilyText, tableInfo } from '../assets/Text/CivilFamilyLawText';
import HeaderSecondary from '../components/Header/HeaderSecondary';
import CivilFamilyHero from '../components/Hero/CivilFamilyHero';

const CivilFamilyLaw = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title3={CivilFamilyText.heroTitle1}
        // title2={CivilFamilyText.heroTitle2}
        textTrans="initial"
        imageSrc={"url('/pictures/HeroImages/desktop-civilfamily3.jpg')"}
        // h1mLeft={'40px'}
        // h2Width={'160px'}
        // wholeWidth={'320px'}
        heroTitle={<CivilFamilyHero />}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
        appearance4={'none'}
      />

      <PagesTextBlock
        title={CivilFamilyText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={CivilFamilyText.text}
      />

      <Table
        tableTitle={CivilFamilyText.tableTitle}
        tablePrice={CivilFamilyText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default CivilFamilyLaw;
