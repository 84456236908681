export const AdministrativeLawText = {
  heroTitle: 'Адміністративне право',
  title: 'Адміністративне право',
  text: "Наша юридична компанія пропонує професійні послуги у галузі адміністративного права. Ми надаємо юридичну підтримку у різних питаннях, пов'язаних з адміністративними правопорушеннями та діяльністю державних органів. Наші послуги включають консультації, складання юридичних документів, представництво у суді та адміністративних органах. Ми готові допомогти у захисті ваших прав та інтересів, а також забезпечити ефективне вирішення адміністративних питань, дотримуючись принципів законності та справедливості.",
  tableTitle: 'Вид адвокатських (юридичних послуг) послуг',
  tablePrice: 'Ціна',
};

export const tableInfo = [
  {
    rowTitle:
      'Оскарження будь – яких рішень, дій чи бездіяльності суб’єктів владних повноважень, органів виконавчої влади та місцевого самоврядування, їх посадових осіб, в тому числі з приводу рішень, дій чи бездіяльності суб’єктів владних повноважень у справах про притягнення до адміністративної відповідальності.',
    rowPrice: '20000 ₴',
  },

  {
    rowTitle:
      'Спори про зобов’язання суб’єкта владних повноважень прийняти рішення або вчинити певні дії або утриматися від вчинення певних дій.',
    rowPrice: '20000 ₴',
  },

  {
    rowTitle:
      'Спори про стягнення з суб’єкта владних повноважень коштів на відшкодування шкоди, завданої його незаконним рішенням, дією або бездіяльністю.',
    rowPrice: '20000 ₴',
  },

  {
    rowTitle:
      'Спори фізичних та юридичних осіб із суб’єктом владних повноважень щодо оскарження його рішень (нормативно – правових актів чи правових актів індивідуальної дії), дій чи бездіяльності.',
    rowPrice: '20000 ₴',
  },

  {
    rowTitle:
      'Спори за зверненням суб’єкта владних повноважень у випадках, встановлених законом (податкові спори, спори з приводу нарахування внесків до Пенсійного фонду, фондів соціального страхування, тощо).',
    rowPrice: '20000 ₴',
  },
  {
    rowTitle:
      'Оскарження указів та розпоряджень Президента України, постанов та розпоряджень Кабінету Міністрів України, нормативно – правових актів міністерств, інших центральних органів виконавчої влади чи іншого суб’єкта владних повноважень, повноваження якого поширюються на всю територію України.',
    rowPrice: '20000 ₴',
  },
  {
    rowTitle:
      'Встановлення законності та відповідності правовим актам вищої юридичної сили нормативно – правових актів місцевих державних адміністрацій, органів місцевого самоврядування, інших суб’єктів владних повноважень.',
    rowPrice: '20000 ₴',
  },
];
