'use client';
import React, { useState } from 'react';
import MenuBtn from './MenuBtn';
import Logo from './Logo';
import MenuListSecondary from './MenuListSecondary';
import MobileMenuListSecondary from './MobileMenuListSecondary';
// import LangSwitcher from './LangSwitcher';
// import Switch from 'react-switch';

// import UkraineColors from '../svg/UkraineColors';
// import UsaColors from '../svg/UsaColors';
// import LanguageSwitcher from './LanguageSwitcher';

// import ua from '../../../public/locales/ua';
// import en from '../../../public/locales/en';

const HeaderSecondary = ({ lang, changeLang }) => {
  const [showMenu, setShowMenu] = useState(true);
  return (
    <header className="bg-[#0A0909] z-[16] w-full max-w-[100%] min-h-[50px] min-[744px]:h-[100px] header-height-744 items-center justify-between text-sm relative  min-[744px]:px-[40px] min-[1440px]:px-[135px] min-[1440px]:mx-auto">
      <nav className="w-full h-full relative flex items-center justify-between max-w-[1170px] mx-auto z-[99]">
        <Logo />

        <div className="flex items-center justify-center space-x-4 min-[744px]:hidden menu-list-hide1024">
          <MenuBtn show={showMenu} setShow={setShowMenu} />
        </div>

        <div className="hidden min-[744px]:flex items-center mr-[15px] menu-list-show1024">
          <MenuListSecondary language={lang} />
          {/* <LangSwitcher lang={lang} changeLang={changeLang} /> */}
        </div>
      </nav>

      <MobileMenuListSecondary
        show={showMenu}
        setShow={setShowMenu}
        language={lang}
        changeLang={changeLang}
      />
    </header>
  );
};

export default HeaderSecondary;
