import * as yup from "yup";

// const phoneRegExp =
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const phoneRegExp = /^\+380\d{9}$/;

export const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Необхідно")
    .matches(phoneRegExp, { message: "Формат має бути +380XXXXXXXXX" }),
  // .number('Only numbers usage')
  // .positive('Only positive numbers usage')
  // .integer('Only integers')
  // .min(12, "Формат номера має бути +380XXXXXXXXX")
  // .max(12, "Формат номера має бути +380XXXXXXXXX"),
  // .min(12, 'Phone number contains of 12 digits')
  // .max(12, 'Phone number contains of 12 digits'),
  name: yup
    .string()
    .required("Необхідно")
    .min(2, "Ім'я не може бути менше 2 літер"),
});
