import React from "react";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import PagesTextBlock from "../components/PagesTextBlock/PagesTextBlock";
import {
  PrivacyPolicyText,
  listInfo1,
  listInfo2,
  listInfo3,
  listInfo4,
  listInfo5,
  listInfo6,
  listInfo7,
  listInfo8,
  listInfo9,
} from "../assets/Text/PrivacyPolicyText";
import ListItem from "../components/PrivacyPolicyList/ListItem";
import ListItemTitle from "../components/PrivacyPolicyList/ListItemTitle";

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto">
      <header className="App-header">
        <Header />
      </header>
      <Hero
        title3={"Політика конфіденційності"}
        textTrans="uppercase"
        imageSrc={"url('/pictures/Home/desktop-hero-clean3.jpg')"}
        appearance1={"none"}
        appearance2={"none"}
        appearance3={"block"}
        h1mLeft={"30px"}
      />

      <PagesTextBlock
        title={PrivacyPolicyText.title}
        mTop={"20px"}
        mBottom={"20px"}
        text={PrivacyPolicyText.text}
      />

      <main className="max-w-[1170px] min-[1440px]:px-0 mx-auto relative z-[2]">
        <ListItemTitle text={"1. Визначення термінів"} />
        <ul>
          {listInfo1?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"2. Загальні положення"} />
        <ul>
          {listInfo2?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"3. Предмет політики конфіденційності"} />
        <ul>
          {listInfo3?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle
          text={"4. Цілі збору персональної інформації користувача"}
        />
        <ul>
          {listInfo4?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle
          text={"5. Способі і терміни обробки персональної інформації"}
        />
        <ul>
          {listInfo5?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"6. Зобов'язання сторін"} />
        <ul>
          {listInfo6?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"7. Відповідальність сторін"} />
        <ul>
          {listInfo7?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"8. Вирішення спорів"} />
        <ul>
          {listInfo8?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>

        <ListItemTitle text={"9. Додаткові умови"} />
        <ul>
          {listInfo9?.map((text) => (
            <ListItem text={text.listText} />
          ))}
        </ul>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
