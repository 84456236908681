import React from 'react';
import { Link } from 'react-router-dom';

const Service = ({
  title = 'Реєстрація, перереєстрація, внесення змін до ЄДРПОУ',
  image = "url('/pictures/Home/mobile-services-1-clean.png')",
  link,
  gridStretch = '',
  itselfWidth = 'auto',
}) => {
  return (
    <li
    style={{gridColumn: gridStretch, width: itselfWidth}}
      className="min-w-[138px] h-[130px] min-[350px]:h-[140px] min-[380px]:h-[160px] min-[420px]:h-[170px] min-[460px]:h-[180px] min-[480px]:h-[190px] min-[500px]:h-[200px] min-[550px]:h-[210px] min-[600px]:h-[230px] min-[640px]:h-[240px] min-[670px]:h-[250px] min-[744px]:h-[300px] min-[800px]:h-[320px] 
    min-[1280px]:h-[350px] cursor-pointer hover:scale-[1.02] focus:scale-[1.02] transition ease-in-out delay-150">
      <Link
        to={link}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }}>
        <div
          className="relative w-full h-full flex items-end bg-no-repeat bg-cover bg-center rounded-[15px] min-[380px]:rounded-[25px] min-[744]:rounded-[60px] min-[1130px]:rounded-[75px]"
          style={{ backgroundImage: image }}>
          <div className="absolute top-0 bottom-[-1px] left-0 right-0 services-backdrop rounded-[15px] z-[10] min-[380px]:rounded-[25px] min-[744]:rounded-[60px] min-[1130px]:rounded-[75px]"></div>
          <h2 className="z-[11] relative services-title mb-[15px] mx-[15px] min-[550px]:text-sm min-[744px]:text-base min-[1130px]:mx-[50px] min-[1130px]:mb-[40px]">
            {title}
          </h2>
        </div>
      </Link>
    </li>
  );
};

export default Service;
