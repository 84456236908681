import React from 'react';
import { motion } from 'framer-motion';

const MenuBtn = ({ show, setShow }) => {
  // const [showBtn, setShowBtn] = useState(true);
  // const handleShow = () => {
  //   show(showBtn);
  //   setShowBtn(!showBtn);
  //   console.log('showBtn in menuBtn', showBtn);
  // };
  return (
    <div className="flex items-center">
      {show ? (
        <>
          <motion.button
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            type="button"
            onClick={() => setShow(!show)}
            className="absolute">
            <img
              src="/pictures/Header/mobile-btn-icon.svg"
              alt="mobile burger menu"
              className="mr-[15px] min-[744px]:hidden"
              width={25}
              height={25}
              priority
            />
          </motion.button>

          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            type="button"
            onClick={() => setShow(!show)}>
            <img
              src="/pictures/Header/mobile-btn-close-icon.svg"
              alt="mobile burger menu"
              className="mr-[15px] min-[744px]:hidden"
              width={25}
              height={25}
              priority
            />
          </motion.button>
        </>
      ) : (
        <>
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            type="button"
            onClick={() => setShow(!show)}
            className="absolute">
            <img
              src="/pictures/Header/mobile-btn-icon.svg"
              alt="mobile burger menu"
              className="mr-[15px] min-[744px]:hidden"
              width={25}
              height={25}
              priority
            />
          </motion.button>

          <motion.button
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            type="button"
            onClick={() => setShow(!show)}>
            <img
              src="/pictures/Header/mobile-btn-close-icon.svg"
              alt="mobile burger menu"
              className="mr-[15px] min-[744px]:hidden"
              width={25}
              height={25}
              priority
            />
          </motion.button>
        </>
      )}
    </div>
  );
};

export default MenuBtn;
