import React from 'react';

const Table = ({ tableTitle, tablePrice, info }) => {
  return (
    <div id='table' className="px-[15px] mt-[20px] mb-[40px] min-[744px]:px-[40px] max-w-[1170px] min-[1280px]:px-0 mx-auto">
      <div class="table w-full border border-white">
        <div class="table-header-group  border border-white">
          <div class="table-row border border-white">
            <div class="table-cell items-center text-center border border-white table__title align-top py-2 min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1440px]:text-[24px]">
              {tableTitle}
            </div>
            <div class="table-cell text-center border border-white table__title px-5 align-middle min-[744px]:text-[20px] min-[744px]:px-[40px] min-[1170px]:px-[70px] min-[1170px]:text-[22px] min-[1440px]:text-[24px]">
              {tablePrice}
            </div>
          </div>
        </div>

        <div class="table-row-group">
          {info?.map((row, idx) => (
            <div class="table-row">
              <div class="table-cell border border-white table__text min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] text-left pl-1 py-[13px] pr-2 align-top">
                {row.rowTitle}
              </div>
              <div class="table-cell border border-white table__text min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] text-center align-middle">
                {row.rowPrice}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
