import React from 'react';
import video from './legal-video-standard.mp4';
import videoSmall from './legal-video-small.mp4';
import videoWidescreen from './legal-video-widescreen.mp4';
import css from './HeroVideoBlock.module.css';

const HeroVideoBlock = () => {
  return (
    <>
      <video
        className={css.video__small}
        width="auto"
        height="auto"
        autoPlay
        muted
        loop
      >
        <source
          className={css.video__small}
          src={videoSmall}
          type="video/mp4"
        />
        Your browser does not support the video tag.
        {/* <div className="backdrop2-hero absolute top-0 left-0 bottom-[0px] right-0 z-1"></div> */}
        {/* <div className="backdrop3-hero absolute top-0 left-0 bottom-[-1px] right-0 z-1"></div> */}
      </video>
      <video
        className={css.video__standard}
        width="auto"
        height="auto"
        autoPlay
        muted
        loop
      >
        <source className={css.video__standard} src={video} type="video/mp4" />
        Your browser does not support the video tag.
        {/* <div className="backdrop2-hero absolute top-0 left-0 bottom-[0px] right-0 z-1"></div> */}
        {/* <div className="backdrop3-hero absolute top-0 left-0 bottom-[-1px] right-0 z-1"></div> */}
      </video>

      <video
        className={css.video__widescreen}
        width="auto"
        height="auto"
        autoPlay
        muted
        loop
      >
        <source
          className={css.video__widescreen}
          src={videoWidescreen}
          type="video/mp4"
        />
        Your browser does not support the video tag.
        {/* <div className="backdrop2-hero absolute top-0 left-0 bottom-[0px] right-0 z-1"></div> */}
        {/* <div className="backdrop3-hero absolute top-0 left-0 bottom-[-1px] right-0 z-1"></div> */}
      </video>
    </>
  );
};

export default HeroVideoBlock;
