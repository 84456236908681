import React from 'react';
import Subtitle from '../Subtitle';
import Paragraph from '../PagesTextBlock/Paragraph';
// import { homeText } from '../../assets/Text/HomeText';

const HomeInfoSection = ({
  id,
  title = '',
  text1 = '',
  text2 = '',
  image = '',
  display = 'block',
  mBottom = '',
  mTop = '',
}) => {
  // const isMoreThan320px = window.matchMedia('(min-width: 320px)').matches;
  const isLessThan1280px = window.matchMedia('(max-width: 1279px)').matches;

  const isMoreThan1280px = window.matchMedia('(min-width: 1280px)').matches;
  return (
    <>
      {isLessThan1280px && (
        <section className="mx-auto relative max-w-[1170px]" id={id}>
          <Subtitle title={title} mBottom={mBottom} mTop={mTop} />
          <div
            className="w-full mx-auto h-[400px] backdrop__about-us relative bg-cover bg-center  bg-no-repeat flex flex-col items-center justify-end"
            style={{ backgroundImage: image }}
          >
            <div className="backdrop__about-us2 absolute top-0 left-0 bottom-[-1px] right-0 z-1"></div>
            <Paragraph text={text1} />
            {/* <p className="w-[100%] px-[15px] relative about__us-text">{text1}</p> */}
          </div>
          <div
            className="w-[100%] px-[15px] min-[744px]:px-[40px] pt-3 text-left mx-auto text-[20px] font-[400] text-[#F9F9F9] min-[1280px]:px-0 "
            style={{ display }}
          >
            <p className="relative about__us-text min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1440px]:text-[24px]">
              {text2}
            </p>
          </div>
        </section>
      )}

      {isMoreThan1280px && (
        <section className="relative w-full" id={id}>
          <div className="max-w-[1170px] mx-auto">
            <Subtitle title={title} mBottom={mBottom} mTop={mTop} />
          </div>
          <div
            className="w-[600px] min-[1330px]:w-[650px] min-[1440px]:w-[705px] absolute h-[400px] min-[1440px]:h-[500px] left-0 right-auto backdrop__about-us  bg-cover bg-center  bg-no-repeat  rounded-r-[100px]"
            style={{ backgroundImage: image }}
          >
            <div className="backdrop__about-us2 absolute top-0 left-0 bottom-[-1px] right-[-3px] z-1 rounded-r-[100px]"></div>
            {/* <Paragraph text={text1} /> */}
            {/* <p className="w-[100%] px-[15px] relative about__us-text">{text1}</p> */}
          </div>
          <div className="flex justify-center mx-auto max-w-[1170px]">
            <div className="w-[600px] min-[1330px]:w-[650px] min-[1440px]:w-[705px] h-[400px] min-[1440px]:h-[500px]"></div>
            <div
              className="w-[500px] min-[1440px]:w-[570px] h-[400px] min-[1440px]:h-[500px] min-[1440px]:ml-[30px] mr-auto pt-3 text-left text-[20px] font-[400] text-[#F9F9F9] min-[1280px]:px-0 "
              style={{ display }}
            >
              <p className=" relative about__us-text min-[744px]:text-[20px] min-[1170px]:text-[22px] min-[1440px]:text-[24px] min-[1440px]:min-w-[600px]">
                {text1}
                <br />
                <br />
                {text2}
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HomeInfoSection;
