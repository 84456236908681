import React from 'react';
import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

// import HomeInfoSection from '../components/HomeInfoSection/HomeInfoSection';
// import Services from '../components/Services/Services';
// import Subtitle from '../components/Subtitle';

import { USRText, tableInfo } from '../assets/Text/USRText';
import HeaderSecondary from '../components/Header/HeaderSecondary';

const UnifiedStateRegister = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title1={USRText.heroTitle1}
        title2={USRText.heroTitle2}
        title3={USRText.heroTitle3}
        textTrans="initial"
        align="left"
        imageSrc={"url('/pictures/HeroImages/desktop-usr3.jpg')"}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
      />

      <PagesTextBlock
        title={USRText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={USRText.text}
      />

      <Table
        tableTitle={USRText.tableTitle}
        tablePrice={USRText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default UnifiedStateRegister;
