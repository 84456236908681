import React from 'react';

const Paragraph = ({ text = '' }) => {
  return (
    <p className="w-[100%] px-[15px] relative about__us-text min-[744px]:text-[20px] min-[744px]:px-[40px] min-[1170px]:text-[22px] min-[1280px]:px-0 min-[1440px]:text-[24px]">
      {text}
    </p>
  );
};

export default Paragraph;
