import React from 'react';

import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

import {
  AdministrativeLawText,
  tableInfo,
} from '../assets/Text/AdministrativeLawText';
import HeaderSecondary from '../components/Header/HeaderSecondary';

const AdministrativeLaw = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title3={AdministrativeLawText.heroTitle}
        textTrans="initial"
        align="center"
        imageSrc={"url('/pictures/HeroImages/desktop-administrative3.jpg')"}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
      />

      <PagesTextBlock
        title={AdministrativeLawText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={AdministrativeLawText.text}
      />

      <Table
        tableTitle={AdministrativeLawText.tableTitle}
        tablePrice={AdministrativeLawText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default AdministrativeLaw;
