import React from 'react';

const Subtitle = ({ title = '', mTop = '', mBottom = '' }) => {
  return (
    <h2
      className="text-[#F9F9F9] text-[20px] min-[744px]:text-[28px] min-[1170px]:text-[36px] min-[1440px]:text-[40px] italic font-[700] ml-[15px] min-[744px]:ml-[40px] min-[1280px]:ml-0"
      style={{ marginTop: mTop, marginBottom: mBottom }}
    >
      {title}
    </h2>
  );
};

export default Subtitle;
