'use client';
import React, { useState } from 'react';
import MenuBtn from './MenuBtn';
import Logo from './Logo';
import MenuList from './MenuList';
import MobileMenuList from './MobileMenuList';
// import LangSwitcher from './LangSwitcher';
// import Switch from 'react-switch';

// import UkraineColors from '../svg/UkraineColors';
// import UsaColors from '../svg/UsaColors';
// import LanguageSwitcher from './LanguageSwitcher';

// import ua from '../../../public/locales/ua';
// import en from '../../../public/locales/en';

const Header = ({ lang, changeLang }) => {
  const [showMenu, setShowMenu] = useState(true);

  // const handleLanguageChange = (l) => {
  //   console.log('locales:', locales);
  //   push('/', undefined, { locale: l });
  // };

  // const t = locale === 'ua' ? ua : en;

  // const onChangeLanguage = (e) => {
  //   const locale = e.target.value;
  //   push(router.pathname, router.asPath, { locale });
  // };
  // const handleMenuDisplay = () => {
  //   setShowMenu(!showMenu);
  //   console.log('showMenu from Header', showMenu);
  // };
  return (
    <div className="bg-[#0A0909] z-[16] w-full max-w-[100%] min-h-[50px] min-[744px]:h-[100px] header-height-744 items-center justify-between text-sm relative  min-[744px]:px-[40px] min-[1440px]:px-[135px] min-[1440px]:mx-auto ">
      <nav className="w-full h-full relative flex items-center justify-between max-w-[1170px] mx-auto z-[99]">
        <Logo />

        <div className="flex items-center justify-center space-x-4 menu-list-hide1024">
          {/* <div className="flex items-center justify-center space-x-4 min-[744px]:hidden menu-list-hide744"> */}
          <MenuBtn show={showMenu} setShow={setShowMenu} />
        </div>

        <div className="hidden items-center mr-[15px] menu-list-flex menu-list-show1024">
          <MenuList language={lang} />
          {/* <LangSwitcher lang={lang} changeLang={changeLang} /> */}
        </div>
      </nav>

      <div>
        <MobileMenuList
          show={showMenu}
          setShow={setShowMenu}
          language={lang}
          changeLang={changeLang}
        />
      </div>
    </div>
  );
};

export default Header;
