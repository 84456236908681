import React from 'react';
import ContactForm from './ContactForm';

const ContactUs = () => {
  return (
    <section
      id="contact_us"
      className="px-[15px] w-full mx-auto min-[744px]:px-[40px] max-w-[1170px] min-[1130px]:px-[100px] min-[1440px]:hidden">
      <div
        className="w-full min-h-[200px] min-[380px]:h-[250px] min-[450px]:h-[280px] min-[550px]:h-[300px] min-[600px]:h-[330px] rounded-t-[100px] relative bg-center bg-cover flex items-center justify-center"
        style={{
          backgroundImage: 'url("/pictures/contact-us.jpg")',
        }}>
        <div className="absolute top-0 bottom-0 left-0 right-0 footer-backdrop rounded-t-[100px] z-[10]"></div>
        <ContactForm />
      </div>
    </section>
  );
};

export default ContactUs;
