export const homeText = {
  heroTitle1: 'OM Legal Services',
  heroTitle2: 'Юридичний захист, якому можна довіряти.',
  title2: 'Про нас',

  text1:
    'Ми - команда OM Legal Services. Ми надаємо весь спектр юридичних послуг та маємо індивідуальний підхід до кожного клієнта. ',
  text2:
    'Наші експерти зможуть проконсультувати вас з питань українського та міжнародного законодавства і права і знайти саме те рішення, яке вас влаштує. Ми маємо багаторічний досвід у веденні юридичних справ та завжди готові до найскладніших проблем. Наші рішення відрізняються надійністю та багатоплановим підходом. Обираючи нас, ви можете бути впевнені у досягненні найкращих результатів. OM Legal Services - це гарантія якості, із якою кожний клієнт отримає саме ті готові юридичні механізми дії, які потрібні.',
  title3: 'Послуги',
  service1: 'Реєстрація, перереєстрація, внесення змін до ЄДРПОУ',
  service2: 'Цивільне та сімейне право',
  service3: 'Кримінальне право',
  service4: 'Адмінистративне право',
  service5: 'Банківське право',
  service6: 'Господарське право',
  service7: 'Послуги з нерухомістю',
  service8: 'Допомога з ДТП',
  title4: 'Чому ми?',
  text3:
    'Ми забезпечуємо повний юридичний супровід на всіх етапах роботи та гарантуємо цілісне вирішення вашої проблеми. Наша команда експертів має багатий досвід і завжди приймає відповідальність за свою роботу. OM Legal Services - це лідери юридичного ринку, які знають свою справу на сто відсотків. Ви можете розраховувати на надійний результат та високу якість наших послуг. Наші експерти завжди готові надати вам індивідуальний підхід та знайти оптимальні рішення для вашої справи.',
};
