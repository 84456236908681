export const PrivacyPolicyText = {
  heroTitle: "Політика конфіденційності",
  title: "Політика конфіденційності",
  text: "Політика конфіденційності персональних даних (далі - Політика конфіденційності) діє відносно всієї інформації, яку даний сайт, на якому розміщений текст цієї Політики конфіденційності, може отримати про Користувача, а також будь-яких програм і продуктів, розміщених на ньому.",
  tableTitle: "Вид адвокатських (юридичних послуг) послуг",
  tablePrice: "Ціна",
};
export const listInfo1 = [
  {
    listText:
      "1.1 У цій Політиці конфіденційності використовуються такі терміни:",
  },
  {
    listText:
      "1.1.1. «Адміністрація сайту» - уповноважені співробітники на управління сайтом, що діють від його імені, які організовують і (або) здійснює обробку персональних даних, а також визначає цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії (операції), що здійснюються з персональними даними.",
  },
  {
    listText:
      "1.1.2. «Персональні дані» - будь-яка інформація, що стосується прямо або побічно певного або визначається фізичній особі (суб'єкту персональних даних).",
  },
  {
    listText:
      "1.1.3. «Обробка персональних даних» - будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних.",
  },
  {
    listText:
      "1.1.4. «Конфіденційність персональних даних» - обов'язкове для дотримання Адміністрацією сайту вимога не допускати їх умисного поширення без згоди суб'єкта персональних даних або наявності іншого законного підстави.",
  },
  {
    listText:
      "1.1.5. «Користувач сайту (далі Користувач)» - особа, яка має доступ до сайту, за допомогою мережі Інтернет і використовує даний сайт для своїх цілей.",
  },
  {
    listText:
      "1.1.6. «Cookies» - невеликий фрагмент даних, відправлений веб-сервером і зберігається на комп'ютері користувача, який веб-клієнт або веб-браузер кожен раз пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного сайту.",
  },
  {
    listText:
      "1.1.7. «IP-адреса» - унікальна мережева адреса вузла в комп'ютерній мережі, побудованої за протоколом IP.",
  },
];

export const listInfo2 = [
  {
    listText:
      "2.1. Використання Користувачем сайту означає згоду з цією Політикою конфіденційності та умовами обробки персональних даних Користувача.",
  },
  {
    listText:
      "2.2. У разі незгоди з умовами Політики конфіденційності Користувач повинен припинити використання сайту.",
  },
  {
    listText:
      "2.3.Надана Політика конфіденційності застосовується тільки до даного сайту. Адміністрація сайту не контролює і не несе відповідальність за сайти третіх осіб, на які Користувач може перейти по посиланнях, доступним на даному сайті.",
  },
  {
    listText:
      "2.4. Адміністрація сайту не перевіряє достовірність персональних даних, що надаються Користувачем сайту.",
  },
];

export const listInfo3 = [
  {
    listText:
      "3.1. Надана Політика конфіденційності встановлює зобов'язання Адміністрації сайту щодо умисного нерозголошення персональних даних, які Користувач надає по різноманітним запитам Адміністрації сайту (наприклад, при реєстрації на сайті, оформленні замовлення, підписки на повідомлення тощо).",
  },
  {
    listText:
      "3.2. Персональні дані, дозволені до обробки в рамках цієї Політики конфіденційності, надаються Користувачем шляхом заповнення спеціальних форм на Сайті і зазвичай включають в себе наступну інформацію:",
  },
  {
    listText: "3.2.1. прізвище, ім'я, по батькові Користувача;",
  },
  {
    listText: "3.2.3. адреса електронної пошти (e-mail);",
  },
  {
    listText: "3.2.4. місце проживання Користувача та інші дані.",
  },
  {
    listText:
      "3.3. Адміністрація сайту також докладає зусиль щодо захисту Персональних даних, які автоматично передаються в процесі відвідування сторінок сайту: \n- IP адреса;\n- інформація з cookies;\n- інформація про браузер (чи іншої програми, яка здійснює доступ до сайту);\n- час доступу;\n- відвіданих адрес сторінок;\n- реферер (адреса попередньої сторінки) і т.п.",
  },
  {
    listText:
      "3.3.1. Відключення cookies може спричинити неможливість доступу до сайту.",
  },
  {
    listText:
      "3.3.2. Сайт здійснює збір статистики про IP-адреси своїх відвідувачів. Дана інформація використовується з метою виявлення і вирішення технічних проблем, для контролю коректності проведених операцій.",
  },
  {
    listText:
      "3.4. Будь-яка інша персональна інформація не обговорені вище (історія покупок, використовувані браузери і операційні системи і т.д.) не підлягає навмисного розголошення, за винятком випадків, передбачених в п.п. 5.2. і 5.3. цієї Політики конфіденційності.",
  },
];

export const listInfo4 = [
  {
    listText:
      "4.1. Персональні дані Користувача Адміністрація сайту може використовувати в цілях:",
  },
  {
    listText:
      "4.1.1. Ідентифікації Користувача, зареєстрованого на сайті, для оформлення замовлення і (або) укладення Договору.",
  },
  {
    listText:
      "4.1.2. Надання Користувачеві доступу до персоналізованих ресурсів сайту.",
  },
  {
    listText:
      "4.1.3. Встановлення з Користувачем зворотного зв'язку, включаючи напрямок повідомлень, запитів, що стосуються використання сайту, надання послуг, обробка запитів і заявок від Користувача.",
  },
  {
    listText:
      "4.1.4. Визначення місця знаходження Користувача для забезпечення безпечного",
  },
  {
    listText:
      "4.1.5. Підтвердження достовірності та повноти персональних даних, наданих Користувачем.",
  },
  {
    listText:
      "4.1.6. Створення облікового запису для здійснення покупок, якщо Користувач дав згоду на створення облікового запису.",
  },
  { listText: "4.1.7. Повідомлення Користувача сайту про стан Замовлення." },
  {
    listText:
      "4.1.8. Обробки і отримання платежів, підтвердження податку або податкових пільг, заперечування платежу, визначення права на отримання кредитної лінії Користувачем.",
  },
  {
    listText:
      "4.1.9. Надання Користувачеві ефективної клієнтської і технічної підтримки при виникненні проблем пов'язаних з використанням сайту.",
  },
  {
    listText:
      "4.1.10. Надання Користувачеві з його згоди, оновлень продукції, спеціальних пропозицій, інформації про ціни, розсилки новин та інших відомостей від імені сайту або від імені партнерів сайту.",
  },
  {
    listText: "4.1.11. Здійснення рекламної діяльності за згодою Користувача.",
  },
  {
    listText:
      "4.1.12. Надання доступу Користувачеві на сторонні сайти або сервіси партнерів даного сайту з метою отримання їх пропозицій, оновлень або послуг.",
  },
];

export const listInfo5 = [
  {
    listText:
      "5.1. Обробка персональних даних Користувача здійснюється без обмеження терміну, будь-яким законним способом, в тому числі в інформаційних системах персональних даних з використанням засобів автоматизації або без використання таких засобів.",
  },
  {
    listText:
      "5.2. Користувач погоджується з тим, що Адміністрація сайту має право передавати персональні дані третім особам, зокрема, кур'єрським службам, організаціями поштового зв'язку, операторам електрозв'язку, виключно з метою виконання заявок Користувача, оформлених на сайті, в рамках Договору публічної оферти.",
  },
  {
    listText:
      "5.3. Персональні дані Користувача можуть бути передані уповноваженим органам державної влади тільки на підставах та в порядку, встановленим чинним законодавством.",
  },
];

export const listInfo6 = [
  { listText: "6.1. Користувач зобов'язується:" },
  {
    listText:
      "6.1.1. Надати коректну і правдиву інформацію про персональні дані, необхідну для користування сайтом.",
  },
  {
    listText:
      "6.1.2. Оновити або доповнити надану інформацію про персональні дані в разі зміни даної інформації.",
  },
  {
    listText:
      "6.1.3. Вживати заходів для захисту доступу до своїх конфіденційних даних, що зберігаються на сайті.",
  },
  { listText: "6.2. Адміністрація сайту зобов'язується:" },
  {
    listText:
      "6.2.1. Використовувати отриману інформацію виключно для цілей, зазначених у п. 4 цієї Політики конфіденційності.",
  },
  {
    listText:
      "6.2.2. Не розголошувати персональні дані Користувача, за винятком п.п. 5.2. і 5.3. цієї Політики Конфіденційності.",
  },
  {
    listText:
      "6.2.3. Здійснити блокування персональних даних, що відносяться до відповідного Користувачеві, з моменту звернення або запиту Користувача або його законного представника або уповноваженого органу з захисту прав суб'єктів персональних даних на період перевірки, в разі виявлення недостовірних персональних даних або неправомірних дій.",
  },
];

export const listInfo7 = [
  {
    listText:
      "7.1. Адміністрація сайту несе відповідальність за умисне розголошення Персональних даних Користувача відповідно до чинного законодавства, за винятком випадків, передбачених п.п. 5.2., 5.3. і 7.2. цієї Політики Конфіденційності.",
  },
  {
    listText:
      "7.2. У разі втрати або розголошення Персональних даних Адміністрація сайту не несе відповідальність, якщо дана конфіденційна інформація:",
  },
  {
    listText: "7.2.1. Стала публічним надбанням до її втрати або розголошення.",
  },
  {
    listText:
      "7.2.2. Була отримана від третьої сторони до моменту її отримання Адміністрацією сайту.",
  },
  {
    listText:
      "7.2.3. Була отримана третіми особами шляхом несанкціонованого доступу до файлів сайту.",
  },
  { listText: "7.2.4. Була розголошена за згодою Користувача." },
  {
    listText:
      "7.3. Користувач несе відповідальність за правомірність, коректність і правдивість наданих Персональних даних відповідно до чинного законодавства.",
  },
];

export const listInfo8 = [
  {
    listText:
      "8.1. До звернення в суд з позовом у спорах, що виникають із відносин між Користувачем сайту і Адміністрацією сайту, обов'язковим є пред'явлення претензії (письмового пропозиції про добровільне врегулювання спору).",
  },
  {
    listText:
      "8.2.Получатель претензії протягом 30 календарних днів з дня отримання претензії, письмово повідомляє заявника претензії про результати розгляду претензії.",
  },
  {
    listText:
      "8.3. При не досягненні угоди - суперечка буде переданий на розгляд до судового органу відповідно до чинного законодавства.",
  },
  {
    listText:
      "8.4. До цій Політиці конфіденційності та відносин між Користувачем і Адміністрацією сайту застосовується чинне законодавство.",
  },
];

export const listInfo9 = [
  {
    listText:
      "9.1. Адміністрація сайту має право вносити зміни в справжню Політику конфіденційності без згоди Користувача.",
  },
  {
    listText:
      "9.2. Нова Політика конфіденційності вступає в силу з моменту її розміщення на Сайті, якщо інше не передбачено новою редакцією Політики конфіденційності.",
  },
];
