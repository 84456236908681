import React from 'react';

import Hero from '../components/Hero/Hero';
import PagesTextBlock from '../components/PagesTextBlock/PagesTextBlock';
import Table from '../components/Table/Table';

import { BankingLawText, tableInfo } from '../assets/Text/BankingLawText';
import HeaderSecondary from '../components/Header/HeaderSecondary';

const BankingLaw = () => {
  return (
    <div>
      <header className="App-header">
        <HeaderSecondary />
      </header>
      <Hero
        title3={BankingLawText.heroTitle}
        textTrans="initial"
        align="center"
        imageSrc={"url('/pictures/HeroImages/desktop-banking3.jpg')"}
        appearance1={'none'}
        appearance2={'none'}
        appearance3={'block'}
      />

      <PagesTextBlock
        title={BankingLawText.title}
        mTop={'20px'}
        mBottom={'20px'}
        text={BankingLawText.text}
      />

      <Table
        tableTitle={BankingLawText.tableTitle}
        tablePrice={BankingLawText.tablePrice}
        info={tableInfo}
      />
    </div>
  );
};

export default BankingLaw;
