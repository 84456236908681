import React from "react";
import { Link } from "react-router-dom";
import { homeText } from "../assets/Text/HomeText";
import HeaderSecondary from "../components/Header/HeaderSecondary";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";

const NotFoundPage = () => {
  return (
    <div className="mx-auto">
      <header className="App-header">
        <Header />
      </header>
      <Hero
        title1={"Сторінку не знайдено"}
        title2={""}
        textTrans="uppercase"
        imageSrc={"url('/pictures/Home/desktop-hero-clean3.jpg')"}
        appearance1={"block"}
        appearance2={"block"}
        h1mLeft={"30px"}
        appearance3={"none"}
      />
      {/* <p>Page not found</p> */}
      {/* Return to the<Link to="/">Home Page</Link> */}
    </div>
  );
};

export default NotFoundPage;
