import Homepage from "../pages/Homepage";
import AdministrativeLaw from "../pages/AdministrativeLaw";
import BankingLaw from "../pages/BankingLaw";
import CivilFamilyLaw from "../pages/CivilFamilyLaw";
import CommercialLaw from "../pages/CommercialLaw";
import CriminalLaw from "../pages/CriminalLaw";
import RealEstate from "../pages/RealEstate";
import RoadTrafficAccident from "../pages/RoadTrafficAccident";
import UnifiedStateRegister from "../pages/UnifiedStateRegister";
import NotFoundPage from "../pages/NotFoundPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";

export const servicesInfo = [
  {
    title: "Реєстрація, перереєстрація, внесення змін до ЄДРПОУ",
    link: "/usr",
    image: "url('/pictures/Home/mobile-services-1-clean.jpg')",
    component: <UnifiedStateRegister />,
  },

  {
    title: "Цивільне та сімейне право",
    link: "/civilfamily",
    image: "url('/pictures/Home/mobile-services-2-clean.jpg')",
    component: <CivilFamilyLaw />,
  },

  {
    title: "Кримінальне право",
    link: "/criminal",
    image: "url('/pictures/Home/mobile-services-3-clean.jpg')",
    component: <CriminalLaw />,
  },

  {
    title: "Адмінистративне право",
    link: "/administrative",
    image: "url('/pictures/Home/mobile-services-4-clean.jpg')",
    component: <AdministrativeLaw />,
  },

  {
    title: "Банківське право",
    link: "/banking",
    image: "url('/pictures/Home/mobile-services-5-clean.jpg')",
    component: <BankingLaw />,
  },

  {
    title: "Господарське право",
    link: "/commercial",
    image: "url('/pictures/Home/mobile-services-6-clean.jpg')",
    component: <CommercialLaw />,
  },

  {
    title: "Послуги з нерухомістю",
    link: "/realestate",
    image: "url('/pictures/Home/mobile-services-7-clean.jpg')",
    // gridStretch: 'span 50% / span 2',
    itselfWidth: "100%",
    component: <RealEstate />,
  },

  {
    title: "Допомога з ДТП",
    link: "/rta",
    image: "url('/pictures/Home/mobile-services-8-clean.jpg')",
    // gridStretch: 'span 2 / span 2',
    itselfWidth: "100%",
    component: <RoadTrafficAccident />,
  },
];

export const servicesInfo2 = [
  {
    title: "Реєстрація, перереєстрація, внесення змін до ЄДРПОУ",
    link: "/usr",
    image: "url('/pictures/Home/mobile-services-1-clean.jpg')",
    component: <UnifiedStateRegister />,
  },

  {
    title: "Цивільне та сімейне право",
    link: "/civilfamily",
    image: "url('/pictures/Home/mobile-services-2-clean.jpg')",
    component: <CivilFamilyLaw />,
  },

  {
    title: "Кримінальне право",
    link: "/criminal",
    image: "url('/pictures/Home/mobile-services-3-clean.jpg')",
    component: <CriminalLaw />,
  },

  {
    title: "Адмінистративне право",
    link: "/administrative",
    image: "url('/pictures/Home/mobile-services-4-clean.jpg')",
    component: <AdministrativeLaw />,
  },

  {
    title: "Банківське право",
    link: "/banking",
    image: "url('/pictures/Home/mobile-services-5-clean.jpg')",
    component: <BankingLaw />,
  },

  {
    title: "Господарське право",
    link: "/commercial",
    image: "url('/pictures/Home/mobile-services-6-clean.jpg')",
    component: <CommercialLaw />,
  },
];

export const servicesInfoLastTwo = [
  {
    title: "Послуги з нерухомістю",
    link: "/realestate",
    image: "url('/pictures/Home/mobile-services-7-clean.jpg')",
    // gridStretch: 'span 50% / span 2',
    itselfWidth: "100%",
    component: <RealEstate />,
  },

  {
    title: "Допомога з ДТП",
    link: "/rta",
    image: "url('/pictures/Home/mobile-services-8-clean.jpg')",
    // gridStretch: 'span 2 / span 2',
    itselfWidth: "100%",
    component: <RoadTrafficAccident />,
  },
];

export const servicesLinks = [
  {
    // title: 'Реєстрація, перереєстрація, внесення змін до ЄДРПОУ',
    link: "/",
    // image: "url('/pictures/Home/mobile-services-1-clean.jpg')",
    component: <Homepage />,
  },

  {
    // title: 'Реєстрація, перереєстрація, внесення змін до ЄДРПОУ',
    link: "/usr",
    // image: "url('/pictures/Home/mobile-services-1-clean.png')",
    component: <UnifiedStateRegister />,
  },

  {
    // title: 'Цивільне та сімейне право',
    link: "/civilfamily",
    // image: "url('/pictures/Home/mobile-services-2-clean.png')",
    component: <CivilFamilyLaw />,
  },

  {
    // title: 'Кримінальне право',
    link: "/criminal",
    // image: "url('/pictures/Home/mobile-services-3-clean.png')",
    component: <CriminalLaw />,
  },

  {
    // title: 'Адмінистративне право',
    link: "/administrative",
    // image: "url('/pictures/Home/mobile-services-4-clean.png')",
    component: <AdministrativeLaw />,
  },

  {
    // title: 'Банківське право',
    link: "/banking",
    // image: "url('/pictures/Home/mobile-services-5-clean.png')",
    component: <BankingLaw />,
  },

  {
    // title: 'Господарське право',
    link: "/commercial",
    // image: "url('/pictures/Home/mobile-services-6-clean.png')",
    component: <CommercialLaw />,
  },

  {
    // title: 'Послуги з нерухомістю',
    link: "/realestate",
    // image: "url('/pictures/Home/mobile-services-7-clean.png')",
    component: <RealEstate />,
  },

  {
    // title: 'Допомога з ДТП',
    link: "/rta",
    // image: "url('/pictures/Home/mobile-services-8-clean.png')",
    component: <RoadTrafficAccident />,
  },
  {
    title: "Політика конфіденційності",
    link: "/privacypolicy",
    component: <PrivacyPolicy />,
  },
  {
    title: "Page not found",
    link: "*",
    component: <NotFoundPage />,
  },
];
